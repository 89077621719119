import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './css/SearchBar.css';

function SearchBar({ onSearch, showNotification }) {
  const [input, setInput] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = (event) => {
    event.preventDefault();
    if (!isSearching && input.length > 1) {
      setIsSearching(true);
      onSearch(input, () => {
        setIsSearching(false);
      });
    } else if (input.length <= 1) {
      showNotification('Error', 'Search input require minimum of 2 characters.');
    }
  };

  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form className="d-flex" onSubmit={handleSearch}>
            <input
              id="searchInput"
              className="form-control me-2"
              type="text"
              placeholder="Search Product"
              aria-label="Search"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button className="btn btn-outline-success cu-search-button" type="submit" disabled={isSearching}>
              {isSearching ? <i className="spinner-border spinner-border-sm"></i> : <i className="bi bi-search"></i>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default SearchBar;
