import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Footer';
import Notification from './Notification';
import SearchBar from './SearchBar';
import StoreColumn from './StoreColumn';
import Sidebar from './Sidebar';
import { saveCookie, getCookie, deleteCookie } from '../utils/CookiesAdanced';
import MetaTags from './MetaTags';
import { API_URL } from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import About from './About';

export class App extends Component {
  constructor(props) {
    super(props);
    const stores = [
      // List of all stores
      { id: 'walmart', name: 'Walmart', locations: [] },
      { id: 'rcss', name: 'Real Canadian Superstore', locations: [] },
      { id: 'tntsm', name: 'T&T Supermarket', locations: [] },
    ];

    // For sidebar default store selections.
    const allStores = new Set(stores.map((store) => store.id));
    // Cookie returns null if it does not exist.
    const savedSelectedStores = new Set(getCookie('selectedStores'));
    let selectedStores = savedSelectedStores || allStores;
    if (selectedStores.size === 0) {
      selectedStores = allStores;
    }

    // Remove any keys that are not in the options array
    selectedStores.forEach((key) => {
      if (!allStores.has(key)) {
        selectedStores.delete(key);
      }
    });

    const selectedWeightUnit = getCookie('selectedWeightUnit') || 'lb';

    for (const store of stores) {
      const locationCookie = getCookie(`stores.${store.id}.location`);
      if (locationCookie != null) {
        store.locations[0] = locationCookie;
      }
    }

    this.state = {
      stores: stores,
      selectedStores: selectedStores, // Stores selected in the sidebar
      selectedlocations: [],
      searchResult: [],
      selectedWeightUnit: selectedWeightUnit,
      notificationMessage: '',
      notificationHeader: '',
      showNotification: false,
      menuIconGlowTimeout: null,
      notificationTimeout: null,
      isMenuIconGlowing: false,
    };
  }

  handlePostalCodeUpdate = async (postalCode, callback = null) => {
    const encodedStores = encodeURIComponent(JSON.stringify(Array.from(this.state.selectedStores)));
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*', // Allow any origin
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    };
    if (process.env.REACT_APP_X_VERCEL_PROTECTION_BYPASS) {
      headers['X-Vercel-Protection-Bypass'] = process.env.REACT_APP_X_VERCEL_PROTECTION_BYPASS;
    }
    const options = {
      method: 'GET',
      headers: headers,
    };

    await fetch(`${API_URL}/locations?postalCode=${postalCode}&stores=${encodedStores}`, options)
      .then((res) => res.json()) // Converts the response body to a JavaScript object.
      .then((data) => {
        if (data.errors) {
          callback && callback(this.state.stores);
          this.showNotification('Error', 'Request error, please contact admin');
          return;
        }
        // Insert data into stores locations
        this.setState(
          (prevState) => {
            const updatedStores = prevState.stores.map((store) => {
              const locationData = data.find((d) => 'id' in d && d.id.toLowerCase() === store.id.toLowerCase());
              return locationData ? { ...store, locations: locationData.locations } : store;
            });
            return { ...prevState, stores: updatedStores };
          },
          () => {
            for (const store of this.state.stores) {
              if (store.locations.length > 0) {
                saveCookie(`stores.${store.id}.location`, store.locations[0]);
              } else {
                deleteCookie(`stores.${store.id}.location`);
              }
            }
            callback && callback(this.state.stores);
          },
        );
      })
      .catch((err) => {
        callback && callback({ error: err });
        console.error(err); // Handle any errors
      });
  };

  handleItemSearch = async (search, callback = null) => {
    // Validate selectedStores and selectedlocations
    const storesWithLocationIds = Array.from(this.state.selectedStores).filter(
      (storeId) => this.state.selectedlocations[storeId],
    );

    if (storesWithLocationIds.length === 0) {
      // Alert the user there is no location selected for search
      callback && callback();
      this.menuIconGlow();
      this.showNotification('Error', 'Please open side menu, set postal code and select store locations.');
      return; // Exit the function to prevent the fetch call
    }

    const storesAndLocationIds = storesWithLocationIds.map((storeId) => {
      return { store_id: storeId, location_id: this.state.selectedlocations[storeId] };
    });
    const encodedStoresAndLocationIds = encodeURIComponent(JSON.stringify(storesAndLocationIds));
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*', // Allow any origin
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    };
    if (process.env.REACT_APP_X_VERCEL_PROTECTION_BYPASS) {
      headers['X-Vercel-Protection-Bypass'] = process.env.REACT_APP_X_VERCEL_PROTECTION_BYPASS;
    }
    const options = {
      method: 'GET',
      headers: headers,
    };

    await fetch(`${API_URL}/search?query=${search}&stores=${encodedStoresAndLocationIds}`, options)
      .then((res) => res.json()) // Converts the response body to a JavaScript object.
      .then((data) => {
        if (data.errors) {
          callback && callback(this.state.stores);
          this.showNotification('Error', 'Request error contact admin');
          return;
        }
        // Add pinned variable to every item.
        data.forEach((store, storeIndex) => {
          store.index = storeIndex;
          store.items.forEach((item, itemIndex) => {
            item.pinned = false;
            item.index = itemIndex; // Preserve original index
          });
        });
        this.setState({ searchResult: data }, () => {
          callback && callback();
        });
      })
      .catch((err) => {
        callback && callback({ error: err });
        console.error(err); // Handle any errors
      });
  };

  handleStoreSelectionChange = (selected) => {
    // Otherwise fetch locations for the store and set store default or first in locale array(Closest to Zip).
    this.setState({ selectedStores: selected });
    saveCookie('selectedStores', Array.from(selected));
  };

  handleWeightUnitSelectionChange = (selected) => {
    this.setState({ selectedWeightUnit: selected });
    saveCookie('selectedWeightUnit', selected);
  };

  handleStoreLocationChange = (selected) => {
    const selectedStore = this.state.stores.find((store) => store.id === selected.store_id);
    const selectedLocation = selectedStore.locations.find((location) => location.id === selected.location_id);

    if (selected.location_id === '') {
      deleteCookie(`stores.${selected.store_id}.location`);
    } else {
      saveCookie(`stores.${selected.store_id}.location`, selectedLocation);
    }

    this.setState((prevState) => {
      const updatedSelectedlocations = prevState.selectedlocations;
      updatedSelectedlocations[selected.store_id] = selected.location_id;
      return { updatedSelectedlocations };
    });
  };

  togglePin = (storeIndex, itemIndex, pinState) => {
    this.setState((prevState) => {
      const updatedSearchResult = [...prevState.searchResult];
      // Update the desired property of the item at the specified index
      updatedSearchResult[storeIndex].items[itemIndex].pinned = !pinState;

      return { ...prevState, searchResult: updatedSearchResult };
    });
  };

  showNotification = (header, message, delay = 5000) => {
    // Clear any existing timeout to reset the notification
    if (this.state.notificationTimeout) {
      clearTimeout(this.state.notificationTimeout);
    }

    this.setState(
      {
        notificationHeader: header,
        notificationMessage: message,
        showNotification: true,
      },
      () => {
        // Set a new timeout
        const notificationTimeout = setTimeout(() => {
          this.setState({ showNotification: false });
        }, delay);

        // Update the state with the new timeout
        this.setState({ notificationTimeout: notificationTimeout });
      },
    );
  };

  menuIconGlow = () => {
    const menuIcon = document.getElementById('menu-icon');
    menuIcon.classList.add('glow');

    // Clear any existing timeout to reset the glow effect
    if (this.state.menuIconGlowTimeout) {
      clearTimeout(this.state.menuIconGlowTimeout);
    }

    // Set a new timeout for the glow effect
    const glowTimeout = setTimeout(() => {
      menuIcon.classList.remove('glow');
      this.setState({ isMenuIconGlowing: false });
    }, 4000);

    // Update the state with the new timeout
    this.setState({
      menuIconGlowTimeout: glowTimeout,
      isMenuIconGlowing: true,
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <MetaTags />
          <div className="cu-content-body">
            <img src="/logo.png" className="cu-logo-size" alt="logo" />
            <Sidebar
              stores={this.state.stores}
              defaultStores={this.state.selectedStores}
              selectedWeightUnit={this.state.selectedWeightUnit}
              showNotification={this.showNotification}
              onSelectionChange={this.handleStoreSelectionChange}
              onWeightUnitChange={this.handleWeightUnitSelectionChange}
              onPostalCodeSubmit={this.handlePostalCodeUpdate}
              onStoreLocationChange={this.handleStoreLocationChange}
            />
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <>
                    <SearchBar onSearch={this.handleItemSearch} showNotification={this.showNotification} />
                    <div className="results">
                      {this.state.searchResult &&
                        this.state.searchResult.length > 0 &&
                        this.state.searchResult.map((store) => (
                          <StoreColumn
                            key={store.id}
                            store={store}
                            selectedWeightUnit={this.state.selectedWeightUnit}
                            togglePin={this.togglePin}
                          />
                        ))}
                    </div>
                  </>
                }
              />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
          <Footer />
          <Notification
            header={this.state.notificationHeader}
            message={this.state.notificationMessage}
            show={this.state.showNotification}
            onClose={() => this.setState({ showNotification: false })}
          />
        </div>
      </Router>
    );
  }
}

export default App;
