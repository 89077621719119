import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Notification.css';

const Notification = ({ header, message, show, onClose }) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (show) {
      toastRef.current.classList.add('show');
    } else {
      toastRef.current.classList.remove('show');
    }
  }, [show]);

  return (
    <div className="toast-container position-fixed top-0 end-0 p-3">
      <div ref={toastRef} className="toast cu-toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header cu-toast-header">
          <img src="./icon_32x32.ico" className="rounded me-2 cu-notification-img" alt="..." />
          <strong className="me-auto" style={{ color: 'red' }}>
            {header}
          </strong>
          <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
        </div>
        <div className="toast-body cu-toast-body" style={{ color: 'red' }}>
          {message}
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notification;
