import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemError from './ItemError';
import ScalableTextBox from './ScalableTextBox';
import { v4 as uuidv4 } from 'uuid';
import './css/StoreColumn.css';

function StoreColumn({ store, selectedWeightUnit, togglePin }) {
  if (!store) {
    console.log('Store data is undefined.');
    return null;
  }

  // Sorting to keep pinned items on top while preserving original order otherwise
  const sortedItems = store.items.slice().sort((a, b) => {
    if (a.pinned === b.pinned) return a.index - b.index;
    return b.pinned ? 1 : -1;
  });

  return (
    <div className="bodyStyle">
      <div className="containerBody">
        <div className="cu-store-name">
          <b>
            <ScalableTextBox text={store.name} />
          </b>
        </div>

        {store.error ? (
          <ItemError text={'Service Not Available'} />
        ) : (
          store.items && store.items.length === 0 && <ItemError text={'No Search Result'} />
        )}
        {sortedItems.map((item) => (
          <Item
            key={uuidv4()}
            item={item}
            selectedWeightUnit={selectedWeightUnit}
            togglePin={() => togglePin(store.index, item.index, item.pinned)}
          />
        ))}
      </div>
    </div>
  );
}

StoreColumn.propTypes = {
  store: PropTypes.object.isRequired,
  selectedWeightUnit: PropTypes.string.isRequired,
  togglePin: PropTypes.func.isRequired,
};

export default StoreColumn;
