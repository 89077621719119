import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './css/Sidebar.css';

function Sidebar({
  stores,
  defaultStores,
  selectedWeightUnit,
  showNotification,
  onSelectionChange,
  onWeightUnitChange,
  onPostalCodeSubmit,
  onStoreLocationChange,
}) {
  const [selectedStores, setSelectedStores] = useState(new Set());
  const [postalCode, setPostalCode] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  // Initialize the state with specific store names checked by default
  useEffect(() => {
    if (!isInitialized) {
      const allStores = new Set(defaultStores);
      const initSelectOptions = {};
      for (const store of stores) {
        initSelectOptions[store.id] = '';
      }
      setSelectedOptions(initSelectOptions);
      setSelectedStores(allStores);
      onSelectionChange(allStores);
      setIsInitialized(true); // Set initialization flag to true after first run

      for (const store of stores) {
        if (store.locations.length > 0) {
          const optionValue = `{"store_id":"${store.id}","location_id":"${store.locations[0].id}"}`;

          setSelectedOptions((prevData) => ({
            ...prevData,
            [store.id]: optionValue,
          }));
          // Update selected value for search.
          onStoreLocationChange(JSON.parse(optionValue));
        }
      }
    }
  }, [stores, defaultStores, onSelectionChange, isInitialized, selectedOptions, onStoreLocationChange]);

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleSubmitPostalCode = () => {
    if (validateCanadianPostalCode(postalCode)) {
      setIsSearching(true);
      onPostalCodeSubmit(postalCode, (updatedStores) => {
        setIsSearching(false);
        const updatedSelectOptions = {};
        for (const store of updatedStores) {
          if (store.locations.length > 0) {
            const optionValue = `{"store_id":"${store.id}","location_id":"${store.locations[0].id}"}`;
            updatedSelectOptions[store.id] = optionValue;

            // Update selected value for search.
            onStoreLocationChange(JSON.parse(optionValue));
          } else {
            onStoreLocationChange(JSON.parse(`{"store_id":"${store.id}","location_id":""}`));
          }
        }
        setSelectedOptions(updatedSelectOptions);
      });
    } else {
      showNotification('Error', 'Please enter a valid Canadian postal code.');
    }
  };

  const validateCanadianPostalCode = (postalCode) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    return regex.test(postalCode.toUpperCase());
  };

  const handleCheckboxChange = (store) => {
    const newSelections = new Set(selectedStores);
    if (newSelections.has(store)) {
      newSelections.delete(store);
    } else {
      newSelections.add(store);
    }

    // Remove any keys that are not in the options array
    stores.forEach((store) => {
      if (!newSelections.has(store.id)) {
        newSelections.delete(store.id);
      }
    });

    setSelectedStores(newSelections);
    onSelectionChange(newSelections);
  };

  const handleStoreLocationChange = (event) => {
    const valueJson = JSON.parse(event.target.value);

    setSelectedOptions((prevData) => ({
      ...prevData,
      [valueJson.store_id]: event.target.value,
    }));

    onStoreLocationChange(valueJson);
  };

  const handleWeightUnitChange = (event) => {
    onWeightUnitChange(event.target.value);
  };

  return (
    <div>
      <div
        id="menu-icon"
        className="bi bi-list cu-list-icon"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas"
        aria-controls="offcanvas"
      ></div>

      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            <img src="/avocado.png" className="cu-avocado-size" alt="avocado" />
            GenericGrocer
          </h5>
          <button
            type="button"
            className="btn-close cu-sidebar-close-button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="cu-title">
            <b>Locale</b>
          </div>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              value={postalCode}
              onChange={handlePostalCodeChange}
              placeholder="Postal Code"
            />
            <button
              className="btn btn-outline-success cu-set-postal-button"
              type="button"
              disabled={isSearching}
              onClick={handleSubmitPostalCode}
            >
              {isSearching ? <i className="spinner-border spinner-border-sm"></i> : <span>Set Postalcode</span>}
            </button>
          </div>

          <div className="cu-title">
            <b>Weight Unit</b>
          </div>
          <div className="input-group">
            <select value={selectedWeightUnit} className="form-select" onChange={handleWeightUnitChange}>
              <option value="lb">Pounds (lb)</option>
              <option value="g">Grams (g)</option>
            </select>
          </div>

          <div className="cu-title">
            <b>Stores</b>
          </div>
          <hr />
          <div className="cu-checkbox-list">
            {stores.map((store) => (
              <div key={store.id} className="form-check cu-checkbox">
                <div className="cu-checkbox-container">
                  <input
                    id={store.id + 'CheckBox'}
                    className="form-check-input cu-checkbox-input"
                    type="checkbox"
                    checked={selectedStores.has(store.id)}
                    onChange={() => handleCheckboxChange(store.id)}
                  />
                </div>
                <div className="cu-store-location">
                  <label className="form-check-label cu-comapny-name" htmlFor={store.id + 'CheckBox'}>
                    {store.name}
                  </label>
                  <select
                    value={selectedOptions[store.id]}
                    className="form-select"
                    onChange={handleStoreLocationChange}
                  >
                    <option value="" disabled>
                      Select Store Location
                    </option>
                    {store.locations.map((location) => (
                      <option key={location.id} value={`{"store_id":"${store.id}","location_id":"${location.id}"}`}>
                        {location.address}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  stores: PropTypes.array.isRequired,
  defaultStores: PropTypes.object.isRequired,
  selectedWeightUnit: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onWeightUnitChange: PropTypes.func.isRequired,
  onPostalCodeSubmit: PropTypes.func.isRequired,
  onStoreLocationChange: PropTypes.func.isRequired,
};

export default Sidebar;
