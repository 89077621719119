import React from 'react';
import PropTypes from 'prop-types';
import ScalableTextBox from './ScalableTextBox';
import './css/Item.css';

const noImageUrl = '/no_image.png';

const handleImageError = (event) => {
  event.target.src = noImageUrl;
};

function Item({ item, selectedWeightUnit, togglePin }) {
  // Format the price to always show two decimal places
  let formattedPrice = Number(item.price).toFixed(2);
  let priceUnit = item.unit;

  if (priceUnit === 'lb' && selectedWeightUnit === 'g') {
    formattedPrice = Number(item.price / 4.53592).toFixed(2);
    priceUnit = '100g';
  }

  return (
    <div className="card cu-item">
      <div className="cu-image-container">
        <img
          src={item.image_url}
          className="cu-image-size"
          alt={item.name}
          onError={handleImageError} // Handle image load error
        />
        {(item.package_size_unit == 'g' || item.package_size_unit == 'ml') && (
          <div className="cu-image-text">
            <i className="cu-image-package-icon bi bi-box2-fill"></i>
            <span>
              {item.package_size_amount} {item.package_size_unit}
            </span>
          </div>
        )}
      </div>
      <div className="card-body">
        <h5 className="card-title">
          <ScalableTextBox text={item.name} />
        </h5>
        <p className="card-text">
          ${formattedPrice}/{priceUnit}
        </p>
        <a href={item.url} className="btn btn-primary cu-button" target="_blank" rel="noopener noreferrer">
          Link
        </a>
        <button onClick={togglePin} className="btn btn-secondary cu-button">
          {item.pinned ? 'Unpin' : 'Pin'}
        </button>
      </div>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  selectedWeightUnit: PropTypes.string.isRequired,
  togglePin: PropTypes.func.isRequired,
};

export default Item;
