import Cookies from 'js-cookie';

const saveCookie = (dataName, data, expires = 3650) => {
  if (data !== null && (typeof data === 'object' || Array.isArray(data))) {
    Cookies.set(dataName, `a${JSON.stringify(data)}`, { expires: expires }); // 3650 days (~10 years)
  } else {
    Cookies.set(dataName, `n${data}`, { expires: expires }); // 3650 days (~10 years)
  }
};

const getCookie = (dataName) => {
  const storedCookie = Cookies.get(dataName);
  if (storedCookie) {
    const prefix = storedCookie.charAt(0);
    const data = storedCookie.slice(1);
    if (prefix === 'a') {
      return JSON.parse(data);
    } else if (prefix === 'n') {
      return data;
    }
  }
  return null; // Return null if cookie does not exist or is invalid
};

const deleteCookie = (dataName) => {
  Cookies.remove(dataName);
};

export { saveCookie, getCookie, deleteCookie };
