import React from 'react';
import PropTypes from 'prop-types';
import ScalableTextBox from './ScalableTextBox';
import './css/Item.css';

const noResultImageUrl = '/placeholder_gray.png';

function ItemError({ text }) {
  return (
    <div className="card cu-item">
      <div className="cu-image-container">
        <img src={noResultImageUrl} className="cu-image-size" alt="placeholder" />
      </div>
      <div className="card-body">
        <h5 className="card-title">
          <ScalableTextBox text={text} />
        </h5>
      </div>
    </div>
  );
}

ItemError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ItemError;
