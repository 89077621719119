import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaTags = () => {
  const env = process.env.REACT_APP_ENV;

  return (
    <HelmetProvider>
      <Helmet>{env === 'staging' && <meta name="robots" content="noindex" />}</Helmet>
    </HelmetProvider>
  );
};

export default MetaTags;
