import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './css/ScalableTextBox.css';

function ScalableTextBox({ text, className }) {
  const textRef = useRef(null);

  useEffect(() => {
    const adjustTextSize = () => {
      const container = textRef.current;
      if (!container) return;

      let currentSize = 20;
      container.style.fontSize = `${currentSize}px`;

      const parentHeight = container.parentNode.offsetHeight;
      const parentWidth = container.parentNode.offsetWidth;

      while ((container.scrollHeight > parentHeight || container.scrollWidth > parentWidth) && currentSize > 0) {
        currentSize--;
        container.style.fontSize = `${currentSize}px`;
      }
    };

    adjustTextSize();
    // Add a resize event listener if the container size might change
    window.addEventListener('resize', adjustTextSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', adjustTextSize);
    };
  }, [text]); // This effect runs again if 'text' changes

  return (
    <div ref={textRef} className={`cu-scalable-text-box ${className || ''}`}>
      <span className="cu-scalable-text">{text}</span>
    </div>
  );
}

ScalableTextBox.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ScalableTextBox;
