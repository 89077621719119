import React from 'react';
import './css/About.css'; // Create a CSS file for the About page if needed

const About = () => {
  return (
    <div className="container cu-about">
      <h1>About</h1>
      <p className="cu-p">
        Welcome to Generic Grocer. Your personal tool for conveniently comparing prices of groceries in <b>Canada</b>.
        <br />
        Currently, it supports a limited number of stores.
      </p>
      <p className="cu-p">
        <b>Disclaimer:</b> Search results are updated every 6 hours, so the price may not be the correct or latest.
        <br />
        I am not responsible for any time wasted because the prices are incorrect. :P <br />
        Click on the item link and set the store location on the supermarket website to ensure the price is correct.
      </p>

      <p className="cu-p">
        <b>Contact:</b> mike@genericgrocer.com <br />
        For bug reports, suggestions, or etc.
      </p>
    </div>
  );
};

export default About;
